import { graphql, useStaticQuery } from 'gatsby'
import React, {useEffect, useState } from 'react'
import { Loader } from '../components/loader'
import SEO from '../components/seo'
import { Hero3Red } from '../components/hero3-red'
import FoodCalculatorFilter from '../components/food-calculator-filter'

const Calculator = () => {
  const [price, setPrice] = useState('420')
  const [oldPrice, setOldPrice] = useState(null)
  const [plan, setPlan] = useState('Zhubnout')

  return (
    <>
      <SEO title="Home" />
      <Hero3Red oldPrice={oldPrice} price={price} plan={plan} />
      <div id="calculator2" />
      <FoodCalculatorFilter
        plan={plan}
        setPlan={setPlan}
        oldPrice={oldPrice}
        price={price}
        setOldPrice={setOldPrice}
        setPrice={setPrice}
        id="calculator"
      />
    </>
  )
}

const CalculatorPage = ({ isAffiliate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    window.localStorage.removeItem('deliveryPrice') // necessary for order form
  }, [])

  return (
    <>
      {site ? <Calculator site={site} isAffiliate={isAffiliate} /> : <Loader />}
    </>
  )
}

export default CalculatorPage
